
export const htmlToElement = (str: string) => {
    let template = document.createElement('template');
    str = str.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = str;
    return template.content.firstChild;
}

export const htmlToElements = (html: string) =>  {
    var template = document.createElement('template');
    template.innerHTML = html;
    return template.content.childNodes;
}


export const insertBefore = (newEl: Element | DocumentFragment, referenceElement: Element | DocumentFragment) => {
    // @ts-ignore
    referenceElement.parentNode.insertBefore(newEl, referenceElement);
}

export const insertAfter = (newEl: Element | DocumentFragment, referenceElement: Element | DocumentFragment) => {
    // @ts-ignore
    referenceElement.parentNode.insertBefore(newEl, referenceElement.nextSibling);
}

export const getNextNode = (afterEl: Element | HTMLElement) => {
    return afterEl.nextSibling;
}
