/**
 *
 * @param htmlString
 */
import {isObject, wait, getFormFieldsData} from "../utils";
import {addFormError, hasAjaxNotification, ajaxNotification} from "../../utils/ajax";
import {Context, createScopedContext} from '../context'


function createElementFromHTML(htmlString: string): Element | null {
    let div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes.
    // @ts-ignore
    return div.firstChild;
}

const defaultOptions = {
    target: '',
    closeOnClickOutside: false,
    backdrop: false,
    draggable: false,
    ajaxLoad: false,
    submitUrl: null,
    submitData: null,
    manually: false,
    disableScrolling: false,
    reloadAfterSubmitSuccess: true,


    submit: true,
    reset: true,
    cancel: true,


    submitLabel: null,


    events: {
        loadDone: null,
        loadFails: null,
        beforeRender: null,
        afterRender: null,
        beforeSubmit: null,
        successSubmit: null,
        faildSubmit: null,
        onSubmit: null,
        onBeforeClose: null,
        onAfterClose: null,
    }
}

interface Options {
    target: String,
    closeOnClickOutside: Boolean | false,
    backdrop: Boolean | false,
    draggable: Boolean | false,
    ajaxLoad: Boolean | false,
    submitUrl: String | null,
    submitData: Object | null,
    manually: Boolean | false,
    disableScrolling: Boolean | false,
    submitLabel: String | null,
    reloadAfterSubmitSuccess: Boolean | false,

    submit: true,
    reset: true,
    cancel: true,

    events: Object | {
        loadDone: null,
        loadFails: null,
        beforeRender: null,
        afterRender: null,
        beforeSubmit: null,
        successSubmit: null,
        faildSubmit: null,
        onSubmit: null,
        onBeforeClose: null,
        onAfterClose: null,
    },
}


function bindDraggable(config: Options, props: object) {
    // @ts-ignore
    if (config.draggable && props.modalTitle) {
// @ts-ignore
        props.dragging = {
            pos1: 0,
            pos2: 0,
            pos3: 0,
            pos4: 0,
        }
// @ts-ignore
        props.modalTitle.addEventListener('mousedown', (e) => dragMouseDown(e, config, props));
    }
}

function dragMouseDown(e: MouseEvent, config: Options, props: object) {
    // @ts-ignore
    if (props.modalContent.classList.contains('dragging')) return

    e = e || window.event;
    e.preventDefault();

    // get the mouse cursor position at startup:
    // @ts-ignore
    props.dragging.pos3 = e.clientX;
    // @ts-ignore
    props.dragging.pos4 = e.clientY;
// @ts-ignore
    props.modalContent.classList.add('dragging')


    document.addEventListener('mouseup', (e) => dragModalMouseUp(e, config, props));
    document.addEventListener('mousemove', (e) => dragModalMouseMove(e, config, props));
}


function dragModalMouseMove(e: MouseEvent, config: Options, props: object) {
    // @ts-ignore
    if (!props.modalContent.classList.contains('dragging')) return

    e.preventDefault();

    // calculate the new cursor position:
    // @ts-ignore
    props.dragging.pos1 = props.dragging.pos3 - e.clientX;
    // @ts-ignore
    props.dragging.pos2 = props.dragging.pos4 - e.clientY;
    // @ts-ignore
    props.dragging.pos3 = e.clientX;
    // @ts-ignore
    props.dragging.pos4 = e.clientY;

    // set the element's new position:
    // @ts-ignore
    props.modalContent.style.top = (props.modalContent.offsetTop - props.dragging.pos2) + "px";

    // @ts-ignore
    props.modalContent.style.left = (props.modalContent.offsetLeft - props.dragging.pos1) + "px";
}

function dragModalMouseUp(e: MouseEvent, config: Options, props: object) {
    e.stopPropagation();

    // @ts-ignore
    props.modalContent.classList.remove('dragging')

    document.removeEventListener('mouseup', (e) => dragModalMouseUp(e, config, props));
    document.removeEventListener('mousemove', (e) => dragModalMouseMove(e, config, props));
}





function _bindModal(ctx: Context, exp: string, get: Function, options?: Options | any) {



    // create backdrop
    let modalBackdrop = document.querySelector('.modal-backdrop')
    if (!modalBackdrop) {
        modalBackdrop = createElementFromHTML('<div class="modal-backdrop"></div>')
        if (modalBackdrop) document.body.appendChild(modalBackdrop);
    }

    // @ts-ignore
    const modal = document.querySelector(options.target);// @ts-ignore
    const modalContent = modal.querySelector('.modal-content')
    const modalTitle = modal.querySelector('.modal-title');

    const close = modal.querySelector('.close')
    const submit = modal.querySelector('.submit');
    const cancel = modal.querySelector('.cancel');
    const reset = modal.querySelector('.reset');
    const form = modal.querySelector('form');


    let props = {
        dragging: {
            pos1: null,
            pos2: null,
            pos3: null,
            pos4: null,
        },

        modal,
        modalContent,
        modalTitle,
        close,
        submit,
        cancel,
        reset,
        form
    }

    const childCtx = createScopedContext(ctx, options)


    if (!options.submit && submit) {
        submit.classList.add('hide');
    }

    if (!options.cancel && cancel) {
        cancel.classList.add('hide');
    }

    if (!options.reset && reset) {
        reset.classList.add('hide');
    }

    if (submit && options.submit && options.submitLabel) {
        submit.innerHTML = options.submitLabel;
    }


    if (typeof options.events.beforeRender === "function") {
        options.events.beforeRender(ctx, options, props);
    }


    if (modalBackdrop) console.log('parent', modalBackdrop.parentNode)

    if (form)
    {
        form.addEventListener('keyup', (evt: KeyboardEvent) => {
            let keyCode = (evt.which ? evt.which : evt.keyCode);
            if (keyCode === 13) {
                evt.preventDefault();
            }
        });

        form.addEventListener('keydown', (evt: KeyboardEvent) => {
            let keyCode = (evt.which ? evt.which : evt.keyCode);
            if (keyCode === 13) {
                evt.preventDefault();
            }
        });
    }

    childCtx.scope.hasScopedForm = () => {
        return form && form.hasOwnProperty('__lv_scope') ? true : false;
    }

    childCtx.scope.getScopedForm = () => {
        return form && form.hasOwnProperty('__lv_scope') ? form.__lv_scope : null;
    }



    childCtx.scope.hideBackdrop = () => {
        if (modalBackdrop) modalBackdrop.classList.remove('show');
    };


    childCtx.scope.submit = (e: Event) => {
        if (!options.submit) {
            return;
        }

        if (typeof options.events.onSubmit === "function") {
            options.events.onSubmit(e, childCtx, props);
        }
        else {

            let url = options.submitUrl;
            if (!url && form) {
                url = form.getAttribute('action');
            }

            if (!url) {
                console.warn('Invalid Modal submit URL!')
                return;
            }

            let postData = form ? getFormFieldsData(form) : {};
            if (isObject(options.submitData)) {
                postData = Object.assign(postData, options.submitData);
            }

            if (typeof options.events.beforeSubmit === "function") {
                let p = options.events.beforeSubmit(modal, childCtx, postData, form);
                if (isObject(p)) {
                    postData = Object.assign(postData, p);
                }
            }

            modal.classList.add('loading');

            // @ts-ignore
            axios.post(url, postData).then(r => {
                modal.classList.remove('loading');
                let hasNotify = hasAjaxNotification(r.data);
                if (hasNotify) {
                    ajaxNotification(r.data);
                }

                if (r.data.success) {
                    document.body.classList.remove('modal-open');

                    childCtx.scope.close();

                    let stop = false;
                    if (hasNotify) {
                        stop = false;
                        if (typeof options.events.successSubmit === "function") {
                            stop = options.events.successSubmit(modal, childCtx, r.data);
                        }

                        if (!stop && options.reloadAfterSubmitSuccess) {
                            wait(1000).then(() => {
                                window.location.reload();
                            });
                        }
                    } else {
                        stop = false;
                        if (typeof options.events.successSubmit === "function") {
                            stop = options.events.successSubmit(modal, childCtx, r.data);
                        }

                        // ganzes Fenster neu laden
                        if (!stop && options.reloadAfterSubmitSuccess) {
                            window.location.reload();
                        }
                    }

                } else if (r.data.errors) {
                    if (typeof options.events.faildSubmit === "function") {
                        options.events.faildSubmit(modal, childCtx, r.data);
                    }
                    if (form) {

                    }
                }
            }).catch((err: any) => {
                modal.classList.remove('loading');

                if (err.response && err.response.data) {
                    ajaxNotification(err.response.data);

                    if (typeof options.events.faildSubmit === "function") {
                        options.events.faildSubmit(modal, childCtx, err.response.data);
                    }
                }
            });
        }
    };

    childCtx.scope.cancel = (e: Event) => {
        if (options.cancel) childCtx.scope.close(e);
    };

    childCtx.scope.reset = (e: Event) => {
        if (options.reset && form) {
            e.preventDefault();
            form.reset();
        }
    };


    childCtx.scope.close = (e: Event) => {
        if (typeof options.events.onBeforeClose === "function") {
            options.events.onBeforeClose(e, childCtx, props);
        }


        if (options.closeOnClickOutside) {
            let targetEl = e.target; // clicked element
            do {

                if(targetEl == modalContent) {
                    return;
                }

                // Go up the DOM
                // @ts-ignore
                targetEl = targetEl.parentNode;
            } while (targetEl);
        }

        // get animation duration
        if (options.closeOnClickOutside) {
            document.removeEventListener("click", childCtx.scope.close);
        }

        // const modalBackdropEndCallback = (e: Event) => {
        //     if (modalBackdrop) {
        //         modalBackdrop.removeEventListener('animationend', modalBackdropEndCallback);
        //         modalBackdrop.classList.remove('show');
        //         modalBackdrop.classList.remove('closing');
        //     }
        // };

        const modalEndCallback = (e: Event) => {

            if (options.backdrop && modalBackdrop) {
                modalBackdrop.classList.remove('show');
                modalBackdrop.classList.remove('closing');
            }

            if (modal) {
                modal.removeEventListener('animationend', modalEndCallback);
                modal.classList.remove('show');
                modal.classList.remove('closing');
            }

            if (options.disableScrolling) {
                document.body.classList.remove('modal-open');
            }


            if (typeof options.events.onAfterClose === "function") {
                options.events.onAfterClose(e, childCtx, props);
            }



            if (form) {
                form.reset();
            }

        };


        if (options.backdrop && modalBackdrop) {
            modalBackdrop.classList.add('closing');
            // modalBackdrop.addEventListener('animationend', modalBackdropEndCallback);
        }

        modal.classList.add('closing');
        modal.addEventListener('animationend', modalEndCallback);
    };




    childCtx.scope.open = (e: Event) => {

        if (options.disableScrolling) {
            document.body.classList.add('modal-open')
        }

        if (options.backdrop && modalBackdrop) modalBackdrop.classList.add('show');

        modal.classList.add('show');

        if (options.draggable) {
            bindDraggable(options, props);
        }

        if (typeof options.events.afterRender === "function") {
            options.events.afterRender(childCtx, options, props, e);
        }

        if (options.closeOnClickOutside) {
            modal.addEventListener("click", childCtx.scope.close);
        }
    };

    if (!options.manually) {
        if (submit && options.submit) submit.addEventListener("click", childCtx.scope.submit);
        if (cancel && options.cancel) cancel.addEventListener("click", childCtx.scope.cancel);
        if (reset && options.reset) reset.addEventListener("click", childCtx.scope.reset);
    }

    if (close) {
        close.addEventListener("click", childCtx.scope.close);
    }

    return childCtx.scope
}

export const makeModal = (ctx: Context, exp: string, get: Function, options?: Options | any) => {
    options = isObject(options) ? Object.assign(defaultOptions, options) : defaultOptions;
    if (!options.target) {
        console.error('Invali modal Target!');
        return;
    }
    return _bindModal(ctx, exp, get, options);
}

export const componentModal = (el: Element, ctx: Context, exp: string, get: Function, options?: Options | any) => {
    if (el.hasOwnProperty('__mounted')) return;

    options = isObject(options) ? Object.assign(defaultOptions, options) : defaultOptions;
    if (!options.target) {
        console.error('Invali modal Target!');
        return;
    }

    // @ts-ignore
    el.__mounted = true;


    options.trigger = el;



    _bindModal(ctx, exp, get, options);

    if (!options.manually) {
        if (el) el.addEventListener("click", ctx.scope.open);
    }

}
