import {Directive} from '.'

export const show: Directive<HTMLElement> = ({el, get, effect}) => {
    // const initialClasses = el.getAttribute('class');
    const initialDisplay = el.style.display;
    const initialComputedStyles = getComputedStyle(el);
    // console.log(initialComputedStyles)

    effect(() => {
        let v = get();
        if (initialComputedStyles.display === 'none') {
            el.style.display = !v ? 'none' : 'inherit'
        }
        else if (initialComputedStyles.display !== 'none' && initialComputedStyles.display) {
            el.style.display = v ? initialComputedStyles.display : 'none'
        }
        else {
            if (initialDisplay === 'none') {
                el.style.display = !v ? 'none' : ''
            } else {
                el.style.display = v ? initialDisplay : 'none'
            }
        }
    })
}
