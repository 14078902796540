import { Directive } from '.'
import { walk } from '../walk'
import {checkAttr} from "../utils";
import {evaluate} from "../eval";

export const component: Directive = ({ el, ctx, exp, get, effect }) => {

    if (el.hasOwnProperty('__component_mounted') ) return;
// @ts-ignore
    el.__component_mounted = true; // @ts-ignore

    // const componentCtx = createScopedContext(ctx, ctx.scope);

    let p;
    let options = {};
    if ((p = checkAttr(el, 'data-params')) && p && typeof p === "string") {

        options = evaluate(ctx.scope, p);
    }

    if (ctx.components.hasOwnProperty(exp))
    {
        ctx.components[exp](el, ctx, exp, get, options);

        // process
        walk(el, ctx)
    }
    else {
        console.warn('Component "'+ exp +'" is not registred!')
    }

}