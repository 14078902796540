// @ts-ignore
export function fieldNameToDots(str: string) {
    return str.replace(/\[/g, '.').replace(/\]/g, '').replace(/\.$/g, '')
}


export function htmlEscape(htmlStr: string) {
    return htmlStr.replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#39;");

}

export function unhtmlEscape(htmlStr: string) {
    htmlStr = htmlStr.replace(/&lt;/g , "<");
    htmlStr = htmlStr.replace(/&gt;/g , ">");
    htmlStr = htmlStr.replace(/&quot;/g , "\"");
    htmlStr = htmlStr.replace(/&#39;/g , "\'");
    htmlStr = htmlStr.replace(/&amp;/g , "&");
    return htmlStr;

}
// @ts-ignore
export function observeDomNodeChange(targetNode, callback: CallableFunction) {

    // Options for the observer (which mutations to observe)
    let config = {
        childList: true,
        attributes: true,
        subtree: true,
        characterData: true
    };

    // Create an observer instance linked to the callback function
    // @ts-ignore
    let observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}

export function stopObserveDomNodeChange(targetNode: any) {
    if (targetNode.hasOwnProperty('observer')) {
        targetNode.observer.disconnect();
    }
}

export function getDomDataElements(validDataNames = []) {
    let output: any = [], x;
    document.querySelectorAll('*').forEach((el) => {
        if (Array.isArray(validDataNames)) {
            for (x = 0; x < validDataNames.length; x++) {
                if (el.attributes.hasOwnProperty('data-'+ validDataNames[x])) {
                    output.push(el)
                    break;
                }
            }
        }
    })
    return output;
}





export function formatFileSize (bytes: any, decimals?: any, kib?: any, maxunit?: string) {
    kib = kib || false
    if (bytes === 0) return '0 Bytes'
    if (isNaN(parseFloat(bytes)) && !isFinite(bytes)) return 'Not an number'
    const k = kib ? 1024 : 1000
    const dm = decimals != null && !isNaN(decimals) && decimals >= 0 ? decimals : 2
    const sizes = kib ? ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB', 'BiB'] : ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB', 'BB']
    let i = Math.floor(Math.log(bytes) / Math.log(k))
    if (maxunit != undefined) {
        const index = sizes.indexOf(maxunit)
        if (index != -1) i = index
    }
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
