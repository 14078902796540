import { reactive } from '@vue/reactivity'
import { Block } from './block'
import { Directive } from './directives'
import {bindContextMethods, Context, createContext} from './context'
import { toDisplayString } from './directives/text'
import { nextTick } from './scheduler'
import {isArray} from "@vue/shared";
import { walk, walkChildren } from './walk'

const internalDirectives = ['once', 'scope', 'bind', 'component', 'effect', 'for', 'html', 'if', 'model', 'on', 'ref', 'show', 'text', 'syntax', 'cloak', 'pre', 'skip'];



const escapeRegex = (str: string) =>
  str.replace(/[-.*+?^${}()|[\]\/\\]/g, '\\$&')


class Laravision {

  ctx: Context;
  rootBlocks: any = null;


  afterMounts = [];
  beforeMounts = [];

  constructor(initialData?: any) {
    // root context
    this.ctx = createContext()
    if (initialData) {
      this.ctx.scope = reactive(initialData)

      // console.log('after reactive', ctx.scope)
      bindContextMethods(this.ctx.scope)

      // handle custom delimiters
      if (initialData.$delimiters) {
        const [open, close] = (this.ctx.delimiters = initialData.$delimiters)
        this.ctx.delimitersRE = new RegExp(
            escapeRegex(open) + '([^]+?)' + escapeRegex(close),
            'g'
        )
      }
    }

    // global internal helpers
    this.ctx.scope.$internalDirectives = internalDirectives
    this.ctx.scope.$ignoreData = []
    this.ctx.scope.$s = toDisplayString
    this.ctx.scope.$nextTick = nextTick
    this.ctx.scope.$refs = Object.create(null);
    this.ctx.scope.$components = Object.create(null);
    this.ctx.scope.$models = Object.create(null);

    this.ctx.scope.walkChildren = (element: Element | DocumentFragment, ctx: Context) => {

      this.handleBeforeMount(element, ctx);

      walkChildren(element, ctx);

      this.handleAfterMount(element, ctx);
    }

    this.ctx.scope.walk = (element: Element | DocumentFragment, ctx: Context) => {
      this.handleBeforeMount(element, ctx);

      walk(element, ctx);

      this.handleAfterMount(element, ctx);
    }
  }



  private handleBeforeMount(element: Element | DocumentFragment, ctx: Context) {
    if (this.beforeMounts.length) {
      this.beforeMounts.forEach((caller) => {
        // @ts-ignore
        caller(this, element, ctx);
      })
    }
  }

  private handleAfterMount(element: Element | DocumentFragment, ctx: Context) {
    if (this.afterMounts.length) {
      this.afterMounts.forEach((caller) => {
        // @ts-ignore
        caller(this, element, ctx);
      })
    }
  }

  beforeMount(call: Function) {
    // @ts-ignore
    this.beforeMounts.push(call);

    return this;
  }

  afterMount(call: Function) {
    // @ts-ignore
    this.afterMounts.push(call);

    return this;
  }


  use(plugin: any, options: Object = {}) {
    // @ts-ignore
    if (typeof plugin.install === "function") {
      // @ts-ignore
      plugin.install(this, options);
    }

    return this;
  }

  ignoreData(dataAttributes: Object | string) {
    if (isArray(dataAttributes)) {
      this.ctx.scope.$ignoreData.concat(dataAttributes);
    }
    else {
      this.ctx.scope.$ignoreData.push(dataAttributes);
    }
  }

  component(name: string, caller: CallableFunction) {
    if (caller) {
      this.ctx.components[name] = caller
      return this
    } else {
      return this.ctx.components[name]
    }
  }


  directive(name: string, def?: Directive) {
    if (def) {
      if (this.ctx.scope.$internalDirectives.indexOf(name) === -1) {
        this.ctx.scope.$internalDirectives.push(name);
      }

      this.ctx.dirs[name] = def
      return this
    } else {
      return this.ctx.dirs[name]
    }
  }

  mount(el?: string | Element | HTMLElement | null, callback?: Function) {
    if (typeof el === 'string') {
      el = document.querySelector(el)
      if (!el) {
        console.error(`selector ${el} has no matching element.`)
        return
      }
    }

    el = el || document.documentElement;
    let roots: Element[] = []

    if ("hasAttribute" in el && el.hasAttribute('scope')) {
      roots = [el]
    }
    else {
      if ("querySelectorAll" in el) {
        roots = [...el.querySelectorAll(`[scope]`)].filter((root) => {
          return !root.matches(`[scope] [scope]`);
        })
      }
    }
    if (typeof roots !== undefined && !roots.length) {
      roots = [el]
    }

    // if (
    //   roots.length === 1 &&
    //   roots[0] === document.documentElement
    // ) {
    //   console.warn(
    //     `Mounting on documentElement - this is non-optimal as petite-vue ` +
    //       `will be forced to crawl the entire page's DOM. ` +
    //       `Consider explicitly marking elements controlled by petite-vue ` +
    //       `with \`scope\`.`
    //   )
    // }



    // @ts-ignore
    this.rootBlocks = roots.map((el) => {
      this.handleBeforeMount(el, this.ctx);

      // @ts-ignore
      const b = new Block(el, this.ctx, true);

      this.handleAfterMount(el, this.ctx);

      return b;
    });



    return this
  }

  unmount() {
    // @ts-ignore
    this.rootBlocks.forEach((block) => block.teardown())
  }
}



export const createApp = (initialData?: any) => {

  return new Laravision(initialData)

  //
  //
  // // root context
  // const ctx = createContext()
  // if (initialData) {
  //   ctx.scope = reactive(initialData)
  //
  //   // console.log('after reactive', ctx.scope)
  //   bindContextMethods(ctx.scope)
  //
  //   // handle custom delimiters
  //   if (initialData.$delimiters) {
  //     const [open, close] = (ctx.delimiters = initialData.$delimiters)
  //     ctx.delimitersRE = new RegExp(
  //       escapeRegex(open) + '([^]+?)' + escapeRegex(close),
  //       'g'
  //     )
  //   }
  // }
  //
  // // global internal helpers
  // ctx.scope.$internalDirectives = internalDirectives
  // ctx.scope.$ignoreData = []
  // ctx.scope.$s = toDisplayString
  // ctx.scope.$nextTick = nextTick
  // ctx.scope.$refs = Object.create(null);
  // ctx.scope.$components = Object.create(null);
  //
  // let rootBlocks: Block[]
  //
  // return {
  //
  //   use(plugin: any, options: Object = {}) {
  //     // @ts-ignore
  //     if (typeof plugin.install === "function") {
  //       // @ts-ignore
  //       plugin.install(this, options);
  //     }
  //
  //     return this;
  //   },
  //
  //   ignoreData(dataAttributes: Object | string) {
  //     if (isArray(dataAttributes)) {
  //       ctx.scope.$ignoreData.concat(dataAttributes);
  //     }
  //     else {
  //       ctx.scope.$ignoreData.push(dataAttributes);
  //     }
  //   },
  //
  //   component(name: string, caller: CallableFunction) {
  //     if (caller) {
  //       ctx.components[name] = caller
  //       return this
  //     } else {
  //       return ctx.components[name]
  //     }
  //   },
  //
  //
  //   directive(name: string, def?: Directive) {
  //     if (def) {
  //
  //       if (ctx.scope.$internalDirectives.indexOf(name) === -1) {
  //         ctx.scope.$internalDirectives.push(name);
  //       }
  //
  //       ctx.dirs[name] = def
  //       return this
  //     } else {
  //       return ctx.dirs[name]
  //     }
  //   },
  //
  //   mount(el?: string | Element | HTMLElement | null) {
  //     if (typeof el === 'string') {
  //       el = document.querySelector(el)
  //       if (!el) {
  //         console.error(`selector ${el} has no matching element.`)
  //         return
  //       }
  //     }
  //
  //     el = el || document.documentElement;
  //     let roots: Element[] = []
  //
  //     if ("hasAttribute" in el && el.hasAttribute('scope')) {
  //       roots = [el]
  //     }
  //     else {
  //       if ("querySelectorAll" in el) {
  //         roots = [...el.querySelectorAll(`[scope]`)].filter((root) => {
  //           return !root.matches(`[scope] [scope]`);
  //         })
  //       }
  //     }
  //     if (typeof roots !== undefined && !roots.length) {
  //       roots = [el]
  //     }
  //
  //     // if (
  //     //   roots.length === 1 &&
  //     //   roots[0] === document.documentElement
  //     // ) {
  //     //   console.warn(
  //     //     `Mounting on documentElement - this is non-optimal as petite-vue ` +
  //     //       `will be forced to crawl the entire page's DOM. ` +
  //     //       `Consider explicitly marking elements controlled by petite-vue ` +
  //     //       `with \`scope\`.`
  //     //   )
  //     // }
  //
  //     // @ts-ignore
  //     rootBlocks = roots.map((el) => new Block(el, ctx, true))
  //     return this
  //
  //   },
  //
  //   unmount() {
  //     rootBlocks.forEach((block) => block.teardown())
  //   }
  // }
}
