// import { isObject} from "./utils";
// import {ICONS as IconsJson} from './tabler';
//
// export const svgIcon = (name: string): string => {
// 	// @ts-ignore
// 	if (isObject(IconsJson) && IconsJson.hasOwnProperty(name)) {
// 		return '<svg\n' +
// 			'    viewBox="0 0 24 24"\n' +
// 			'    width="16"\n' +
// 			'    height="16"\n' +
// 			'    stroke-width="1.75"\n' +
// 			'    class="tabler-icon"\n' +
// 			'    role="img"\n' +
// 			'    aria-hidden="true"\n' +
// 			'    stroke="currentColor"\n' +
// 			'    preserveAspectRatio="xMidYMid meet">\n' +
// 			// @ts-ignore
// 			IconsJson[name] +
// 			'</svg>';
// 	}
// 	return '';
// }



export const svgIcon = (paths: String) => {
    const startSvg = `<svg width="18" height="18" viewBox="0 0 24 24" class="tabler-icon" stroke-width="1.5" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true" stroke="currentColor" preserveAspectRatio="xMidYMid meet">`;
    const endSvg = `</svg>`;

    return startSvg + paths + endSvg;
}
