import {builtInDirectives, Directive} from './directives'
import {component} from './directives/component'
import {_if} from './directives/if'
import {_for} from './directives/for'
import {bind} from './directives/bind'
import {on} from './directives/on'
import {text} from './directives/text'
import {evaluate} from './eval'
import {checkAttr, getFormFieldsData} from './utils'
import {ref} from './directives/ref'
import {Context, createScopedContext} from './context'
import {isObject} from "@vue/shared";

const dirRE = /^(?:data-|:|@)/
const modifierRE = /\.([\w-]+)/g

export let inOnce = false

export const walk = (node: Node, ctx: Context): ChildNode | null | void => {
    const type = node.nodeType;
    const parentCtx = ctx;
    if (type === 1) {
        // Element
        const el = node as Element
        if (el.hasAttribute('data-pre') || el.hasAttribute('data-skip')) {
            return
        }

        checkAttr(el, 'data-cloak')

        let exp: string | null


        // v-if
        if ((exp = checkAttr(el, 'data-if'))) {
            // @ts-ignore
            el.__lv_scope = ctx.scope;
            return _if(el, exp, ctx)
        }

        // v-for
        if ((exp = checkAttr(el, 'data-for'))) {
            // @ts-ignore
            el.__lv_scope = ctx.scope;
            return _for(el, exp, ctx)
        }

        // if ((exp = checkAttr(el, 'data-component'))) {
        //   el.__lv_scope = ctx.scope;
        //   return component(el, exp, ctx)
        // }


        let allAutoForms: Object = {};

        // Generate models form all forms with scope "data-autoform"
        if (el.hasAttribute('data-autoform')) {
            // @ts-ignore
            let exp0 = checkAttr(el, 'data-autoform');
            let formEl = el.tagName === 'FORM' ? [el] : el.querySelectorAll('form');

            if (formEl) {
                formEl.forEach((formEl, y) => {
                    let formname;
                    if (formEl.getAttribute('name')) {
                        formname = formEl.getAttribute('name');
                    }
                    else if (formEl.getAttribute('id')) {
                        formname = formEl.getAttribute('id');
                        formname = formname ? formname.replace(/\-/g, '_') : formname;
                        if (formname) {
                            formEl.setAttribute('name', formname);
                        }
                    }
                    else {
                        formname = 'form_' + y;
                        formEl.setAttribute('name', formname);
                    }

                    // @ts-ignore
                    const formModel = getFormFieldsData(formEl);

                    // @ts-ignore
                    if (allAutoForms.hasOwnProperty(formname)) {
                        // @ts-ignore
                        allAutoForms[formname] = Object.assign(formModel, allAutoForms[formname])
                    } else {
                        // @ts-ignore
                        allAutoForms[formname] = formModel
                    }
                });
            }

        }


        // v-scope
        if ((exp = checkAttr(el, 'data-scope')) || exp === '') {
            const scope = exp ? evaluate(ctx.scope, exp) : {};
            scope.$root = el;

            if (Object.keys(allAutoForms).length) {

                if (scope.hasOwnProperty('$models') && !isObject(scope.$models)) {
                    console.error('The property $models must declare as Object!')
                    return;
                }

                if (!scope.hasOwnProperty('$models')) {
                    scope.$models = {};
                }

                scope.$models = Object.assign(scope.$models, allAutoForms);
            }

            ctx = createScopedContext(ctx, scope)
            if (scope.$template) {
                resolveTemplate(el, scope.$template)
            }
        } else {
            if (Object.keys(allAutoForms).length) {
                const scope = {$models: allAutoForms};
                ctx = createScopedContext(ctx, scope)
// @ts-ignore
                if (scope.$template) {
                    // @ts-ignore
                    resolveTemplate(el, scope.$template)
                }
            }
        }

        if (ctx !== parentCtx) {
            ctx.scope.$parent = parentCtx;
        }




// @ts-ignore
        el.__lv_scope = ctx.scope;

        if ((exp = checkAttr(el, 'data-component'))) {
            return applyDirective(el, component, exp, ctx)
        }

        // // v-for
        // if ((exp = checkAttr(el, 'data-component'))) {
        //   el.__lv_scope = ctx.scope;
        //   return component(el, exp, ctx)
        // }

        // v-once
        const hasVOnce = checkAttr(el, 'data-once') != null
        if (hasVOnce) {
            inOnce = true
        }

        // ref
        if ((exp = checkAttr(el, 'ref'))) {
            if (ctx !== parentCtx) {
                applyDirective(el, ref, `"${exp}"`, parentCtx)
            }

            applyDirective(el, ref, `"${exp}"`, ctx)
        }

        // process children first before self attrs
        walkChildren(el, ctx)


        // other directives
        const deferred: [string, string][] = []
        for (const {name, value} of [...el.attributes]) {

            if (dirRE.test(name) && name !== 'data-cloak') {
                if (name === 'data-model') {
                    // defer v-model since it relies on :value bindings to be processed
                    // first, but also before v-on listeners (#73)
                    deferred.unshift([name, value])
                } else if (name[0] === '@' || /^data-on\b/.test(name)) {
                    deferred.push([name, value])
                } else {
                    if (ctx.scope.$ignoreData.indexOf(name) === -1) {
                        processDirective(el, name, value, ctx)
                    }
                }
            }

        }


        for (const [name, value] of deferred) {
            if (ctx.scope.$ignoreData.indexOf(name) === -1) {
                processDirective(el, name, value, ctx)
            }
        }

        if (hasVOnce) {
            inOnce = false
        }
    } else if (type === 3) {
        // Text
        const data = (node as Text).data
        if (data.includes(ctx.delimiters[0])) {
            let segments: string[] = []
            let lastIndex = 0
            let match
            while ((match = ctx.delimitersRE.exec(data))) {
                const leading = data.slice(lastIndex, match.index)
                if (leading) segments.push(JSON.stringify(leading))
                segments.push(`$s(${match[1]})`)
                lastIndex = match.index + match[0].length
            }
            if (lastIndex < data.length) {
                segments.push(JSON.stringify(data.slice(lastIndex)))
            }
            applyDirective(node, text, segments.join('+'), ctx)
        }
    } else if (type === 11) {
        walkChildren(node as DocumentFragment, ctx)
    }
}

export const walkChildren = (node: Element | DocumentFragment, ctx: Context) => {
    let child = node.firstChild
    while (child) {
        child = walk(child, ctx) || child.nextSibling
    }
}

const processDirective = (
    el: Element,
    raw: string,
    exp: string,
    ctx: Context
) => {
    let dir: Directive
    let arg: string | undefined
    let modifiers: Record<string, true> | undefined

    // modifiers
    raw = raw.replace(modifierRE, (_, m) => {
        ;(modifiers || (modifiers = {}))[m] = true
        return ''
    });

    let matchData = raw.match(/^data-([a-z]+)/);
    if (!matchData) {
        console.error(`unknown custom directive ${raw}.`)
        return
    }

    if (matchData[1]) {
        if (ctx.scope.$internalDirectives.indexOf(matchData[1]) === -1) {
            return;
        }
    }

    console.info('processDirective', matchData[1])

    if (matchData[1] === 'bind') {
        dir = bind
        arg = raw.slice(matchData[0].length + 1)
    } else if (matchData[1] === 'on') {
        dir = on
        arg = raw.slice(matchData[0].length + 1)
    } else if (matchData[1] === 'component') {
        dir = component
        arg = raw.slice(matchData[0].length + 1)
    }
    else if (raw[0] === ':') {
        dir = bind
        arg = raw.slice(1)
    } else if (raw[0] === '@') {
        dir = on
        arg = raw.slice(1)
    } else {
        const argIndex = raw.indexOf(':');
        let start = 2;
        if (matchData[1]) {
            start = 5;
        }
        const dirName = argIndex > 0 ? raw.slice(start, argIndex) : raw.slice(start)
        dir = builtInDirectives[dirName] || ctx.dirs[dirName]
        arg = argIndex > 0 ? raw.slice(argIndex + 1) : undefined
    }

    if (dir) {
        if (dir === bind && arg === 'ref') dir = ref
        applyDirective(el, dir, exp, ctx, arg, modifiers)
        el.removeAttribute(raw)
    } else {
        console.warn(`unknown custom directive ${raw}.`)
    }
}

const applyDirective = (
    el: Node,
    dir: Directive<any>,
    exp: string,
    ctx: Context,
    arg?: string,
    modifiers?: Record<string, true>
) => {
    const get = (e = exp) => evaluate(ctx.scope, e, el)
    const cleanup = dir({
        el,
        get,
        effect: ctx.effect,
        ctx,
        exp,
        arg,
        modifiers
    })
    if (cleanup) {
        ctx.cleanups.push(cleanup)
    }
}

/**
 * get the <template> by id param template mut begin with # as selector
 * @param el
 * @param template
 */
const resolveTemplate = (el: Element, template: string) => {
    if (template[0] === '#') {
        const templateEl = document.querySelector(template)
        if (!templateEl) {
            console.error(
                `template selector ${template} has no matching <template> element.`
            )
        }

        el.appendChild((templateEl as HTMLTemplateElement).content.cloneNode(true))
        return
    }
    el.innerHTML = template
}
