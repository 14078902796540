import {Context} from "../context";

// @ts-ignore
import Prism from 'prismjs/components/prism-core.min.js';


import 'prismjs/components/prism-markup.min.js';
import 'prismjs/components/prism-markup-templating.min.js';
import 'prismjs/components/prism-clike.min.js';

import 'prismjs/components/prism-bash.min.js';
import 'prismjs/components/prism-css.min.js';
import 'prismjs/components/prism-javascript.min.js';
import 'prismjs/components/prism-yaml.min.js';
import 'prismjs/components/prism-json.min.js';
import 'prismjs/components/prism-php.min.js';
import 'prismjs/components/prism-sql.min.js';
import 'prismjs/components/prism-python.min.js';
import 'prismjs/components/prism-scss.min.js';
import 'prismjs/components/prism-sass.min.js';
import 'prismjs/components/prism-shell-session.min.js';

import 'prismjs/plugins/line-numbers/prism-line-numbers.min.js';
import 'prismjs/plugins/treeview/prism-treeview.js';


Prism.manual = true;
// import 'prismjs/themes/prism-dark.min.css';

//
//
// Prism.languages.treeview = {
//     'treeview-part': {
//         pattern: /^.+/m,
//         inside: {
//             'entry-line': [
//                 {
//                     pattern: /\|-- |├── /,
//                     alias: 'line-h'
//                 },
//                 {
//                     pattern: /\| {3}|│ {3}/,
//                     alias: 'line-v'
//                 },
//                 {
//                     pattern: /`-- |└── /,
//                     alias: 'line-v-last'
//                 },
//                 {
//                     pattern: / {4}/,
//                     alias: 'line-v-gap'
//                 }
//             ],
//             'entry-name': {
//                 pattern: /.*\S.*/,
//                 inside: {
//                     // symlink
//                     'operator': / -> /,
//                 }
//             }
//         }
//     }
// };

// Prism.languages.files = Prism.languages.treeview;
//
// Prism.hooks.add('wrap', function (env) {
//     if (env.language === 'treeview' && env.type === 'entry-name') {
//         let classes = env.classes;
//         let folderPattern = /(^|[^\\])\/\s*$/;
//         if (folderPattern.test(env.content)) {
//             // folder
//
//             // remove trailing /
//             env.content = env.content.replace(folderPattern, '$1');
//             classes.push('dir');
//         } else {
//             // file
//
//             // remove trailing file marker
//             env.content = env.content.replace(/(^|[^\\])[=*|]\s*$/, '$1');
//             let parts = env.content.toLowerCase().replace(/\s+/g, '').split('.');
//             while (parts.length > 1) {
//                 parts.shift();
//                 // Ex. 'foo.min.js' would become '<span class="token keyword ext-min-js ext-js">foo.min.js</span>'
//                 classes.push('ext-' + parts.join('-'));
//             }
//         }
//
//         if (env.content[0] === '.') {
//             classes.push('dotfile');
//         }
//     }
// });

export const componentSyntax = (el: Element, ctx: Context, exp: string, get: Function, options?: Object | any) => {
    if (el.hasOwnProperty('__mounted')) return;

    // @ts-ignore
    el.__mounted = true;

    options = Object.assign({
        lang: 'plain',
        linenumbers: true,
        theme: 'dracula'
    }, options ?? {})

    let config = {characterData: true, childList: true, subtree: false};
    let observer = new MutationObserver(function(mutations){
        mutations.forEach(function(mutation){
            if (mutation.type === 'childList' ) {
                if (observer) {
                    observer.takeRecords();
                    observer.disconnect();
                }

                Prism.highlightElement(mutation.target as HTMLElement);

                if (observer) {
                    observer.observe(mutation.target, config);
                }
            }
        });
    });

    let codeTag = el.tagName === 'CODE' ? [el] : el.querySelectorAll('code')



    codeTag.forEach((ele: Element) => {

        ele.setAttribute('data-pre', '');

        if (options.lang) {
            if (!ele.getAttribute('class')) {
                ele.setAttribute('class', 'language-' + options.lang)
            }
        }

        if (options.linenumbers) {
            let pre = ele.closest('pre')
            if (pre) {
                pre.setAttribute('data-pre', '');
                pre.setAttribute('data-skip', '');

                if (!pre.classList.contains('line-numbers')) {
                    pre.classList.add('line-numbers')
                }
            }
        }

        let cls = ele.getAttribute('class');

        if (cls && cls.match(/language-[a-z0-9\-_]+/g)) {
            Prism.highlightElement(ele);
            observer.observe(ele, config);
        }
    });

    console.log('Demo Component called', ctx, options)

}
