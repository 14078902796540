import {componentSyntax} from "./../components/syntax";
import {evaluate} from "../eval";
import {Directive} from "./index";

/**
 *        el,
 *         get,
 *         effect: ctx.effect,
 *         ctx,
 *         exp,
 *         arg,
 *         modifiers
 */
export const syntax: Directive = ({ el, get, exp, effect, ctx, arg, modifiers }) => {
    return componentSyntax(el, ctx, 'syntax', get, evaluate(ctx.scope, exp) ); // (el: Element, ctx: Context, exp: string, get, options)
}