import { serialize as FormSerialize } from 'object-to-formdata';
import {fieldNameToDots} from './helpers'
const {deleteProperty} = Reflect;

export const checkAttr = (el: Element, name: string): string | null => {
    const val = el.getAttribute(name)
    if (val != null) el.removeAttribute(name)
    return val
}

export const listen = (
    el: Element,
    event: string,
    handler: any,
    options?: any
) => {
    el.addEventListener(event, handler, options)
}


export const isProxy = (obj: any): boolean => {
    try {
        // @ts-ignore
        postMessage(obj, window);
    } catch (error) {
        // @ts-ignore
        return error && error.code === 25;
    }

    return false;
};



export const isObject = (value: any): boolean => {
    return (typeof value === 'object' && value !== null) || typeof value === 'function';
};

export const isUnsafeKey = (key: string): boolean => {
    return key === '__proto__' || key === 'constructor' || key === 'prototype';
};

export const isValidObject = (val: any): boolean => {
    return isObject(val) || Array.isArray(val) || typeof val === 'function';
}

const assignProp = (obj: Object, prop: string, value: any, options?: Object): Object => {
    if (isUnsafeKey(prop)) {
        throw new Error(`Cannot set unsafe key: "${prop}"`);
    }

    options = isObject(options) ? options : { merge: null }

    // Delete property when "value" is undefined
    if (value === undefined) {
        deleteProperty(obj, prop);
    }
    /** @ts-ignore */
    else if (options && options.merge) {
        /** @ts-ignore */
        const merge = options.merge === 'function' ? options.merge : Object.assign;

        // Only merge plain objects
        // @ts-ignore
        if (merge && isObject(obj[prop]) && isObject(value)) {
            // @ts-ignore
            obj[prop] = merge(obj[prop], value);
        } else {
            // @ts-ignore
            obj[prop] = value;
        }

    } else {
        // @ts-ignore
        obj[prop] = value;
    }
    return obj;
};

export const setValue = (target: object, path: string, value: any, options?: Object): Object => {
    if (!path || !isObject(target)) return target;

    const keys = path.split('.');
    let obj = target;

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        const next = keys[i + 1];

        if (isUnsafeKey(key)) {
            throw new Error(`Cannot set unsafe key: "${key}"`);
        }

        if (next === undefined) {
            assignProp(obj, key, value, options);
            break;
        }
// @ts-ignore
        if (typeof next === 'number' && !Array.isArray(obj[key])) {
            // @ts-ignore
            obj = obj[key] = [];
            continue;
        }
// @ts-ignore
        if (!isObject(obj[key])) {
            // @ts-ignore
            obj[key] = {};
        }
// @ts-ignore
        obj = obj[key];
    }

    return target;
}

function join(segs: any, joinChar:string): string {
    return segs[0] + joinChar + segs[1];
}

function split(path: string, splitChar: string): string[] {
    return path.split(splitChar);
}


export const getValue = (target: Object, path: string, options?: Object): any => {
    if (!isObject(options)) {
        options = {default: options};
    }

    if (!isValidObject(target)) {// @ts-ignore
        return typeof options.default !== 'undefined' ? options.default : target;
    }


    const isArray = Array.isArray(path);
    const isString = true;
    const splitChar = '.';
    const joinChar = '.';

    if (!isString && !isArray) {
        return target;
    }


    let segs = isArray ? path : split(path, splitChar);
    let len = segs.length;
    let idx = 0;

    do {
        let prop = segs[idx];
        if (typeof prop === 'number') {
            prop = String(prop);
        }

        while (prop && prop.slice(-1) === '\\') {
            prop = join([prop.slice(0, -1), segs[++idx] || ''], joinChar);
        }

        if (prop in target) {
            // @ts-ignore
            target = target[prop];
        } else {
            let hasProp = false;
            let n = idx + 1;

            while (n < len) {
                prop = join([prop, segs[n++]], joinChar);

                if ((hasProp = prop in target)) {
                    // @ts-ignore
                    target = target[prop];
                    idx = n - 1;
                    break;
                }
            }

            if (!hasProp) {// @ts-ignore
                return options.default;
            }
        }
    } while (++idx < len && isValidObject(target));

    if (idx === len) {
        return target;
    }
// @ts-ignore
    return options.default;


}


export const getFormFieldsData = (form: HTMLFormElement): Object => {
    let postData = {};
    for (let i = 0; i < form.elements.length; i++) {
        const formEl = form.elements[i];
        /**
         * @var formEl HTMLElement
         */
        // @ts-ignore
        if (formEl.type !== "submit" && formEl.type !== "button" && formEl.name) {
            // @ts-ignore
            let isArrayValue = formEl.name.match(/\[\]/g);
            isArrayValue = isArrayValue ? isArrayValue.length > 0 : false;

            // @ts-ignore
            let cleanName = fieldNameToDots(formEl.name);

            // @ts-ignore
            let current = getValue(postData, cleanName);
            let setVal = null;
            if (isArrayValue) {
                if (!Array.isArray(current)) {
                    setVal = [];
                }
                else {
                    setVal = current;
                }
            }
            else {
                setVal = current ? current : null;
            }

            postData = setValue(postData, cleanName, setVal);

            // @ts-ignore
            if ((formEl.tagName == "INPUT" || formEl.tagName == "TEXTAREA") && formEl.name) {

                if (isArrayValue) {// @ts-ignore
                    if ((formEl.type == 'checkbox' || formEl.type == 'radio')) {
                        // @ts-ignore
                        if (formEl.checked) {
                            // @ts-ignore
                            postData = setValue(postData, cleanName, formEl.value);
                        }
                    } else {
                        // @ts-ignore
                        if (formEl.type === 'file') {
                            postData = setValue(postData, cleanName, null);
                        }
                        else {
                            // @ts-ignore
                            postData = setValue(postData, cleanName, formEl.value);
                        }

                    }
                } else {// @ts-ignore
                    if ((formEl.type == 'checkbox' || formEl.type == 'radio')) {

                        // @ts-ignore
                        if (formEl.checked) {// @ts-ignore
                            postData = setValue(postData, cleanName, formEl.value);
                        }

                    } else {
                        // @ts-ignore
                        if (formEl.type == 'file') {
                            postData = setValue(postData, cleanName, null);
                        } else {// @ts-ignore
                            postData = setValue(postData, cleanName, formEl.value);
                        }

                    }
                }
            }
// @ts-ignore
            else if (formEl.tagName == "SELECT" && formEl.name) {

                if (formEl.hasAttribute('multiple')) {
                    isArrayValue = true;
                }


                if (isArrayValue) {// @ts-ignore
                    if (typeof formEl.selectedOptions !== "undefined") {
// @ts-ignore
                        let options = [];
// @ts-ignore
                        Array.from(formEl.selectedOptions).forEach((r) => {
                            // @ts-ignore
                            options.push(r.attributes.value ? r.attributes.value.value : null);
                        })
// @ts-ignore
                        postData = setValue(postData, cleanName, options);
                    } else {
                        postData = setValue(postData, cleanName, []);
                    }

                } else {
                    // @ts-ignore
                    postData = setValue(postData, cleanName, typeof formEl.selectedOptions !== "undefined" && typeof formEl.selectedOptions[0] !== "undefined" && formEl.selectedOptions[0].attributes.value ? formEl.selectedOptions[0].attributes.value.value : null);
                }
            } else {
                // @ts-ignore
                if (formEl.name) {
                    // @ts-ignore
                    if (formEl.type == 'file') {
                    } else {
                        // @ts-ignore
                        postData = setValue(postData, cleanName, formEl.value);
                    }
                }
            }

        }
    }

    return postData
}


export const when = (call: CallableFunction | null = null): Promise<unknown> => {
    if (typeof call === "function") {
        return new Promise((resolve: Function, reject: Function) => {
            call(resolve, reject)
        })
    }

    return new Promise((resolve: Function, reject: Function) => {
        resolve();
    });
}


export const wait = (ms?: number) : Promise<unknown> => {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export const isUndefined = (value: any): boolean => {
    return value === undefined;
}

export const isBlob = (value: any, isReactNative: boolean) => {
    return isReactNative
        ? isObject(value) && !isUndefined(value.uri)
        : isObject(value) &&
        typeof value.size === 'number' &&
        typeof value.type === 'string' &&
        typeof value.slice === 'function';
}



export const isFile = (value: any, isReactNative: boolean): boolean => {
    // @ts-ignore
    return (isBlob(value, isReactNative) && typeof value.name === 'string' && (isObject(value.lastModifiedDate) || typeof value.lastModified === 'number') );
}

export const convertJsonToFormData = (data: object) => {
    return FormSerialize(data);
}

